import React, { useState, useEffect } from 'react';
import '../styles/contact.css';
import ReactGA from 'react-ga4';

const Contact = () => {
	useEffect(() => {
		ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: `Contact` });
	}, []);

	//Email form
	const [emailForm, setEmailForm] = useState({
		name: '',
		email: '',
		message: '',
	});
	//Result of message
	const [result, setResult] = useState('');
	//Status of sending message
	const [status, setStatus] = useState('Submit');

	function resetEmailForm() {
		setEmailForm({ name: '', email: '', message: '' });
	}

	function handleEmailFormChange(event) {
		setEmailForm((prevEmailData) => {
			return {
				...prevEmailData,
				[event.target.name]: event.target.value,
			};
		});

		if (result.length > 0) {
			setResult('');
		}
	}

	const handleSubmit = async (e) => {
		setResult('');
		e.preventDefault();
		setStatus('Sending...');

		const { name, email, message } = e.target.elements;

		let details = {
			name: name.value,
			email: email.value,
			message: message.value,
		};

		try {
			let response = await fetch('https://teddywashere.art:5500/send', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json;charset=utf-8',
				},
				body: JSON.stringify(details),
			});
			setStatus('Submit');
			let result = await response.json();

			if (result.status === 'success') {
				setResult(
					'Message Sent! Will get back to you as soon as possible. Please check your Spam folder!'
				);
				resetEmailForm();
			} else if (result.status === 'fail') {
				alert('Uh oh! Message failed to send.');
			}
		} catch (error) {
			console.error(error);
			setStatus('Submit');
			setResult(
				'Uh oh! Issues with submitting message. Please feel free to reach out through social media instead. Thank you for your understanding.'
			);
		}
	};

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<div className='fade flex f-center-vertical min-height '>
			<div className='container '>

				<h2 className='numbered-title text-black blur-container contact-title'>
					<span aria-hidden='true'>04</span>Contact
				</h2>
				<div className='flex f-center-vertical'>

					<span className='contact-span text-black ff-serif fs-400 blur-container contact-div'>
						Drop us a message here or give us a call – we'd love to hear from you!
					</span>





					<h2 className='numbered-title uppercase text-dark t-align-center'>
						Send Us A message
					</h2>
					<span className='contact-span text-black ff-serif fs-400 blur-container'>
						Artistic Inquiries and Commissions Welcome!
					</span>


					<form
						id='contact-form'
						className='contact-form '
						onSubmit={handleSubmit}
						method='POST'>
						<input
							className='contact-blur-container text-black'
							placeholder='name*'
							type='text'
							name='name'
							required={true}
							value={emailForm.name}
							onChange={handleEmailFormChange}
						/>
						<input
							className='contact-blur-container text-black'
							placeholder='email address*'
							type='email'
							name='email'
							required={true}
							value={emailForm.email}
							onChange={handleEmailFormChange}
						/>
						<textarea
							className='contact-blur-container text-black'
							maxLength={600}
							placeholder='message (max 600 characters)*'
							name='message'
							required={true}
							value={emailForm.message}
							onChange={handleEmailFormChange}
						/>
						<button type='submit'>{status}</button>
						<h3 className='contact-span text-dark ff-serif fs-500 blur-container'>
							{result}
						</h3>
					</form>




					<h2 className='numbered-title uppercase text-dark t-align-center fs-400'>
						Stay Connected
					</h2>
					<span className='contact-span text-black ff-serif fs-450 blur-container'>
						Follow us for the latest updates
					</span>



					<div className='flex contact-links f-center-horizontal f-center-vertical'>


						<a
							href='https://www.tiktok.com/@teddy.was.here.art'
							className='icon-link contact-button ff-sans-cond uppercase f-center-vertical'
							target='_blank'
							rel='noreferrer'>
							TikTok
						</a>

						<a
							href='https://www.instagram.com/teddy.was.here.art/'
							className='icon-link contact-button ff-sans-cond uppercase f-center-vertical'
							target='_blank'
							rel='noreferrer'>
							Instagram
						</a>
						<a
							href='mailto: teddy.was.here.art@gmail.com'
							target='_blank'
							rel='noreferrer'
							className='icon-link contact-button ff-sans-cond uppercase f-center-vertical'>
							Email
						</a>


					</div>
					<h2 className='numbered-title uppercase text-dark t-align-center fs-400'>
						Contact Info
					</h2>
					<span className='contact-span text-black ff-serif fs-400 blur-container'>
						Whether you're in Dallas, TX or beyond, reach out to us for personalized paintings, painting parties, custom shadow boxes or any creative request.<br />  We're here to bring artful joy wherever you are.
					</span>
					<div className="d-block contact-span contact-div">
						<p className='ff-sans-cond uppercase text-black letter-spacing-2 bold'>Teddy Was Here - Art Studio</p>
						<p className='ff-sans-cond uppercase text-black letter-spacing-2 bold'>Teresa - Owner/Artist</p>
						<p className='ff-sans-cond uppercase text-black letter-spacing-2 bold'>Dallas, TX</p>
						<a
							href='tel:972-626-1688'
							className=' ff-sans-cond uppercase text-dark'
						>
							972-626-1688
						</a>
					</div>

				</div>
			</div>
		</div>
	);
};

export default Contact;
