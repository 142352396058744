import React, { useEffect } from 'react'
import data from '../data.json';
import CurrentEvent from '../components/CurrentEvent';
import PastEvent from '../components/PastEvent';

const Events = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='grid-container grid-container--events flow min-height fade'>
            <h1 className='numbered-title text-black blur-container'>
                <span aria-hidden='true'>05</span>Upcoming Events
            </h1>
            <div className='events-details '>


                <div className='blur-container' id="upcoming-event">
                    <span className='  text-black ff-serif fs-400 t-align-center'>
                        From art exhibitions to community workshops, there's always something happening at Teddy Was Here. Join us for a memorable experience filled with creativity, inspiration, and connection.
                    </span>

                    <div className='cards flex'>
                        {data.current_events.map((event) => (
                            <CurrentEvent event={event} key={event.id} />
                        )

                        )}
                    </div>
                </div>

                <h2 className='numbered-title text-dark blur-container' >
                    Past Events
                </h2>
                <div className='blur-container' id="upcoming-event">


                    <div className='cards flex'>
                        {data.past_events.map((event) => (
                            <PastEvent event={event} key={event.id} />
                        )

                        )}

                    </div>
                </div></div>
        </div>

    )
}

export default Events