import "./App.css";
import Main from "./pages/MainPage";
import Homepage from "./pages/Home";
import Products from "./pages/Products";
import About from "./pages/AboutSite";
import Error from "./pages/Error";
import Contact from "./pages/Contact";
import Artwork from "./pages/Artwork";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import ReactGA from 'react-ga4';
import Events from "./pages/Events";



ReactGA.initialize('G-BTPJRR68HS');

function App() {
	return (
		<BrowserRouter basename="/">
			<Routes>
				<Route path="" element={<Main />}>
					<Route index element={<Homepage />} />
					<Route path="services" element={<Products />} />
					<Route path="artwork" element={<Artwork />} />
					<Route path="about" element={<About />} />
					<Route path="contact" element={<Contact />} />
					<Route path="events" element={<Events />} />
					<Route path="*" element={<Error />} />
				</Route>
			</Routes>
		</BrowserRouter>
	);
}

export default App;
