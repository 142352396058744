import React from 'react'

function Event(props) {
    return (
        <div className='flex event-card f-center-vertical  '>
            <h2 className='fs-600 ff-serif uppercase text-dark'>{props.event.title}</h2>
            <h3 className='text-dark ff-sans-cond bold'>{props.event.subtitle}</h3>
            <img
                className='fade events-img'
                src={require(`../images/${props.event.images.png}`)}
                alt="Texas Forever Fest"
            />


            <div>
                <p className='text-dark ff-sans-cond bold'>{props.event.date}</p>
                <p className='text-dark ff-sans-cond bold'>{props.event.time}</p>
                <p className='text-dark ff-sans-cond bold'>{props.event.location}</p>
                <p className='text-dark ff-sans-cond bold'>{props.event.address}</p>
            </div>

            <div >
                <p className='text-black ff-sans-normal bold'>{props.event.desc}</p>
                <ul className='list text-black ff-sans-cond'>
                    {props.event.items.map((i) => (
                        <li>{i}</li>
                    )
                    )}
                </ul>

            </div>

            <div>
                <a href={props.event.link} className='text-black'
                    rel='noreferrer'><button className='services-button bg-accent fs-400 ff-sans-normal text-dark bold uppercase'>{props.event.linkText}</button></a>
            </div>

        </div>
    );
}

export default Event