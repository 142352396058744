import React, { useEffect } from 'react';
import '../styles/main.css';
import { Link } from 'react-router-dom';
import Logo from '../images/shared/logo.png';
import ReactGA from 'react-ga4';

const Home = () => {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	useEffect(() => {
		ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Home" });
	}, []);

	return (
		<main
			id='main'
			className='grid-container grid-container--home fade min-height'>
			<div className='flex f-center-vertical main-title'>

				<img
					src={Logo}
					alt='Teddy was Here Art Studio logo'
					className='logo'
				/>
			</div>
			<div className='flex f-center-vertical'>
				<div>
					<h1 className='text-black fs-800 ff-serif uppercase letter-spacing-1'>
						Leaving a mark with my {" "}
						<span className='fs-900 ff-sans-extra uppercase text-dark letter-spacing-2'>
							ART
						</span>
					</h1>
					<p className='text-black ff-sans-normal fs-500 bold'>
						Let's explore the endless possibilities of creativity and empower each other to reach new heights of expression and imagination.
					</p>
				</div>


				<div>
					<Link
						to='/services'
						className='large-button uppercase flex bold'>
						<h2 className='fs-400 ff-sans-normal bold'>
							Check Out
						</h2>


						<h2 className='fs-400 ff-sans-normal bold'>
							Services provided by
						</h2>
						<h1 className='ff-serif bold'>
							Teddy Was Here
						</h1>

					</Link>
				</div>
			</div>



		</main>
	);
};

export default Home;
