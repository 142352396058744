import React from 'react'

const PastEvent = (props) => {
    return (
        <div className='flex event-card f-center-vertical  '>
            {/* <h2 className='fs-600 ff-serif uppercase text-dark'>{props.event.title}</h2>
            <h3 className='text-dark ff-sans-cond bold'>{props.event.subtitle}</h3> */}
            <img
                className='fade events-img'
                src={require(`../images/${props.event.images.png}`)}
                alt="Texas Forever Fest"
            />

        </div>

    )
}

export default PastEvent