import React, { useEffect, useState } from 'react';
import data from '../data.json';
import '../styles/main.css';
import '../styles/products.css';
import ReactGA from 'react-ga4';
import Gallery from '../components/Gallery';


function Artwork() {
    const [currentTab, setCurrentTab] = useState('tab1');

    const handleTabClick = (e) => {
        setCurrentTab(e.target.id);

    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: `Artwork: ${currentTab}` });
    }, [currentTab]);


    return (
        <div className='grid-container grid-container--events flow min-height fade'>
            <h1 className='numbered-title text-black blur-container'>
                <span aria-hidden='true'>02</span> Original Art
            </h1>
            <div className='art-details events-details '>
                <div className='blur-container'>
                    <span className='text-black ff-serif fs-400 t-align-center'>
                        Discover Teddy's Original Art Collection, featuring pieces crafted for commissions, showcased at vendor events, and brought to life at our vibrant Paint & Sip parties!
                    </span>
                </div>

                <div className='tab-list art-tabs underline-indicators flex fade blur-container'>
                    {data.artwork_tabs.map((tab, i) => (
                        <button
                            key={i}
                            id={tab.id}
                            aria-pressed={currentTab === `${tab.id}` ? 'true' : 'false'}
                            className='uppercase text-black letter-spacing-2 bg-transparent ff-sans-cond fs-300'
                            onClick={handleTabClick}>
                            {tab.name}
                        </button>
                    ))}
                </div>

                {data.artwork_tabs.map((tab, i) => (
                    currentTab === `${tab.id}` && (
                        <div key={i}><h2 className='numbered-title uppercase text-dark'>
                            {tab.name}
                        </h2>
                            <Gallery classname="fade" items={tab.images} />
                        </div>
                    )
                ))
                }



            </div>







        </div >
    )
}

export default Artwork