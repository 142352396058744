import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ReactGA from 'react-ga4';

const Error = () => {

	useEffect(() => {
		ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: `Error` });
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<section className="flex f-center-vertical min-height ">

			<h2 className=" fs-800 ff-serif uppercase text-black">OH NO!</h2>

			<span className="text-dark ff-sans-normal fs-450">
				404 - Page Not Found
			</span>

			<div>
				<Link
					to="/"
					className='large-button uppercase flex bold'
				>
					<span className="text-dark bold ff-sans-cond fs-450">
						Let's Go Home!
					</span>
				</Link>
			</div>
		</section>
	);
};

export default Error;
