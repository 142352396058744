import React from 'react'
import { Link } from 'react-router-dom';
import '../styles/banner.css';

function Banner() {
    return (
        <div className="banner-container">
            <div className="banner-content flex ">
                <h2 className='ff-sans-normal text-dark fs-450'>Don't Miss Out!</h2>
                <p className='ff-sans-normal text-dark bold fs-300'>Join us at our upcoming event for a day filled with creativity and fun!</p>
                <Link to="/events" className="ff-sans-normal text-white fs-300 banner-btn">Learn More</Link>
            </div>
        </div>
    );
}

export default Banner