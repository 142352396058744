import React from 'react'
import { Link } from 'react-router-dom';
import Logo from '../images/shared/logo.png';
import Tiktok from '../images/shared/tiktok.svg';
import Instagram from '../images/shared/instagram.svg';
import Email from '../images/shared/email.svg';

function Footer() {
    return (
        <footer className='footer '>

            <div className="f-container flex ">

                <div className='f-logo-div flex'>
                    <Link
                        to='/'
                        className='no-underline'

                    >
                        <img
                            src={Logo}
                            alt='Teddy was Here Art Studio logo'
                            className='f-logo'
                        />
                    </Link>

                </div>

                <div className='flex f-contact-div '>
                    <Link
                        to='/'
                        className='no-underline'

                        id='home'>
                        <h1 className='text-dark fs-500 ff-serif uppercase letter-spacing-1'>
                            Teddy Was Here
                        </h1>
                    </Link>

                    <p className='text-black ff-sans-cond fs-500 letter-spacing-3'>
                        Dip into Creativity: Teddy was Here - Where Honey Bears Bring Imagination to Life.
                    </p>

                    <div className='flex f-connect'>
                        <p className='bold text-dark ff-sans-cond fs-400 uppercase'>
                            Connect With Us
                        </p>

                        <div className='flex f-center-horizontal'>
                            <a
                                href='https://www.instagram.com/teddy.was.here.art/'
                                className='icon-link'
                                target='_blank'
                                rel='noreferrer'>
                                <span className='sr-only'>Instagram</span>
                                <img
                                    src={Instagram}
                                    alt='instagram logo'
                                    className='icon'
                                />
                            </a>
                            <a
                                href='https://www.tiktok.com/@teddy.was.here.art'
                                className='icon-link'
                                target='_blank'
                                rel='noreferrer'>
                                <span className='sr-only'>Tiktok</span>
                                <img
                                    src={Tiktok}
                                    alt='tiktok logo'
                                    className='icon'
                                />
                            </a>
                            <a
                                href='mailto: teddy.was.here.art@gmail.com'
                                className='icon-link'>
                                <span className='sr-only'>Email</span>
                                <img
                                    src={Email}
                                    alt='email logo'
                                    className='icon'
                                />
                            </a>
                        </div>


                    </div>

                </div>
                <nav className='flex f-nav-div '>  <p className='bold text-dark ff-sans-cond fs-400 uppercase'>
                    Navigate
                </p>
                    <ul className='bold text-dark ff-sans-cond fs-300 uppercase'>
                        <li><Link
                            className='ff-sans-cond uppercase text-black letter-spacing-2' to="/">Home</Link></li>
                        <li><Link
                            className='ff-sans-cond uppercase text-black letter-spacing-2' to="/services">Art Services</Link></li>
                        <li><Link
                            className='ff-sans-cond uppercase text-black letter-spacing-2' to="/artwork">Original Art</Link></li>
                        <li><Link
                            className='ff-sans-cond uppercase text-black letter-spacing-2' to="/about">About</Link></li>
                        <li><Link
                            className='ff-sans-cond uppercase text-black letter-spacing-2' to="/contact">Contact</Link></li>
                        <li><Link
                            className='ff-sans-cond uppercase text-black letter-spacing-2' to="/events">Events</Link></li>
                    </ul>
                </nav>
            </div>


            <div ><p className='bold text-dark '> Copyright &copy; 2024. Teddy Was Here. All Rights Reserved. Built by <a className='bold text-dark ' href="https://elenadiaz.space" target="_blank" rel="noopener noreferrer">Elena Diaz</a></p> </div>        </footer>
    )
}

export default Footer