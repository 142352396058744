import React, { useEffect } from 'react';
import { useState } from 'react';
import '../styles/main.css';
import '../styles/about.css';
import data from '../data.json';
import ReactGA from 'react-ga4';

const AboutSite = () => {
	const [currentTab, setCurrentTab] = useState('1');

	useEffect(() => {
		ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: `About: ${currentTab}` });
	}, [currentTab]);

	const handleTabClick = (e) => {
		setCurrentTab(e.target.id);

	};

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<div
			id='about'
			className='about grid-container grid-container--about flow fade min-height'>
			<h2 className='numbered-title text-black blur-container'>
				<span aria-hidden='true'>03</span>About
			</h2>

			{data.destinations.map((tab, i) => (
				<div
					key={i}
					className='about-img fade'>
					{currentTab === `${tab.id}` && (
						<img
							className='fade'
							src={require(`../images/${tab.images.png}`)}
							alt={tab.name}
						/>
					)}
				</div>
			))}

			<div className='tab-list underline-indicators flex fade blur-container'>
				{data.destinations.map((tab, i) => (
					<button
						key={i}
						id={tab.id}
						aria-pressed={currentTab === `${tab.id}` ? 'true' : 'false'}
						className='uppercase text-black letter-spacing-2 bg-transparent ff-sans-cond fs-300'
						onClick={handleTabClick}>
						{tab.name}
					</button>
				))}
			</div>

			{data.destinations.map((tab, i) => (
				<article
					className='about-info fade blur-container'
					key={i}>
					{currentTab === `${tab.id}` && (
						<div className='fade'>
							<h2 className='about-title flex fs-700 ff-serif uppercase text-dark'>
								{tab.name}
							</h2>
							<p className='text-black ff-sans-normal fs-400 bold'>
								{tab.description}
							</p>
							<div className='about-meta flex'></div>
						</div>
					)}
				</article>
			))}
		</div>
	);
};

export default AboutSite;
