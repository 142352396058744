import React, { useState } from 'react';
import '../styles/main.css';
import { Outlet } from 'react-router-dom';
import { Link } from 'react-router-dom';
import paint from '../images/shared/paintsplatter.svg';
import Footer from '../components/Footer';
import pause from '../images/shared/animate/pause.png';
import start from '../images/shared/animate/start.png';
import Banner from '../components/Banner';

const MainPage = () => {

	const [bganimate, setBganimate] = useState(false);


	//when user clicks hamburger button
	function toggleMenu() {
		//Open Menu
		const nav = document.querySelector('.primary-navigation');
		const navToggle = document.querySelector('.mobile-nav-toggle');

		//navToggle.addEventListener("click", () => {
		const visibility = nav.getAttribute('data-visible');

		if (visibility === 'false') {
			nav.setAttribute('data-visible', true);
			navToggle.setAttribute('aria-expanded', true);
		} else {
			nav.setAttribute('data-visible', false);
			navToggle.setAttribute('aria-expanded', false);
		}
		//});
	}

	function toggleAnimation() {
		const mainbg = document.getElementById("mainbg");

		if (bganimate) {
			mainbg.classList.remove("PauseAnimation")
			mainbg.classList.add("PlayAnimation")
		} else {
			mainbg.classList.add("PauseAnimation")
			mainbg.classList.remove("PlayAnimation")

		}

		setBganimate(!bganimate);
	}

	return (

		<div id='mainbg' className='d-block home home-body AppBackground AppBackgroundAnimate'>
			<a className="skip-to-content" href="#main">
				Skip to content
			</a>
			{/* <Banner /> */}
			{/*<!-- navigation -->*/}
			<header className='primary-header flex'>

				<button
					onClick={toggleMenu}
					className='mobile-nav-toggle'
					aria-controls='primary-navigation'>
					<span
						className='sr-only'
						aria-expanded='false'>
						Menu
					</span>
				</button>
				<nav>
					<ul
						id='primary-navigation'
						data-visible='false'
						className='primary-navigation underline-indicators ff-sans-cond fs-300 flex'>
						<li

							key='home'>
							<button className='menu-button'>
								<Link
									to='/'
									className='ff-sans-cond uppercase text-black letter-spacing-2'

									id='home'>
									<span aria-hidden='true'>00</span>
									Home
								</Link>
							</button>
						</li>
						<li

							key='services'>
							<button className='menu-button'>
								<Link
									className='ff-sans-cond uppercase text-black letter-spacing-2'
									to='/services'
									id='services'
								>
									<span aria-hidden='true'>01</span>Art Services
								</Link>
							</button>
						</li>
						<li

							key='artwork'>
							<button className='menu-button'>
								<Link
									className='ff-sans-cond uppercase text-black letter-spacing-2'
									to='/artwork'
									id='artwork'
								>
									<span aria-hidden='true'>02</span>Original Art
								</Link>
							</button>
						</li>
						<li

							key='about'>
							<button className='menu-button'>
								<Link
									className='ff-sans-cond uppercase text-black letter-spacing-2'
									to='/about'

									id='about'>
									<span aria-hidden='true'>03</span>About
								</Link>
							</button>

						</li>
						<li

							key='contact'>
							<button className='menu-button'>
								<Link
									className='ff-sans-cond uppercase text-black letter-spacing-2'
									to='/contact'

									id='contact'>
									<span aria-hidden='true'>04</span>Contact
								</Link>
							</button>
						</li>
						<li

							key='events'>
							<button className='menu-button'>
								<Link
									className='ff-sans-cond uppercase text-black letter-spacing-2'
									to='/events'

									id='events'>
									<span aria-hidden='true'>05</span>Events
								</Link>
							</button>
						</li>
					</ul>
				</nav>
			</header>

			<div className='animationContainer'>
				<button onClick={toggleAnimation} className='ani-button tooltip' title='Play/Pause Animation'>{bganimate ?
					<>
						<span className="tooltiptext ff-sans-cond fs-300">Play</span>
						<span
							className='sr-only'
							aria-expanded='false'>
							Start Animation
						</span>
						<img
							src={start}
							alt='start'
							className='a-img'
						/>
					</>
					:
					<>
						<span className="tooltiptext  ff-sans-cond fs-300">Pause</span>
						<span
							className='sr-only'
							aria-expanded='false'>
							Pause Animation
						</span>
						<img
							src={pause}
							alt='pause'
							className='a-img'
						/>
					</>

				}</button>

			</div>

			<div>
				<Outlet />
			</div>




			<div className='bottom-container'>
				<img
					src={paint}
					alt='paint'
					className='paint-img'
				/>
				<Footer />


			</div>
		</div>

	);
};

export default MainPage;
