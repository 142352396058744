import React, { useEffect } from 'react';
import data from '../data.json';
import '../styles/main.css';
import '../styles/products.css';
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';


const Products = () => {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	useEffect(() => {
		ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Services" });
	}, []);

	return (
		<div className='grid-container grid-container--events flow min-height fade'>
			<h1 className='numbered-title text-black blur-container'>
				<span aria-hidden='true'>01</span>Art Services Offered
			</h1>
			<div className='events-details '>


				{/* 
				<div className='blur-container'>
					<span className='text-black ff-serif fs-400 t-align-center'>
						Whether you're looking for a vibrant gathering at our cozy venue or prefer the artistic celebration to come to you, We are thrilled to bring the joy of creativity to any space! Contact us for more information.
					</span>
				</div> */}


				<div className='cards flex'>
					{data.services.map((tab, i) => (

						<div className="fade flex card f-center-vertical blur-container" key={i}>
							<h2 className='s-title d-block fs-600 ff-serif uppercase text-dark'>{tab.name}</h2>
							<img
								className='fade'
								src={require(`../images/${tab.images.png}`)}
								alt={tab.name}
							/>
							<div className=' flex f-center-vertical card-description'>
								<p className='text-black ff-sans-normal bold'>{tab.bio}</p>
								{tab.link && <Link to={tab.link} className='no-underline' rel='noreferrer'><button className='services-button bg-accent fs-450 ff-sans-normal text-dark bold uppercase'>{tab.linktext}</button></Link>}
							</div>


						</div>

					))}
				</div>


			</div>




		</div >
	);
};

export default Products;
