import React from 'react'
import '../styles/gallery.css';

function Gallery(props) {
    return (
        // className="gallery"
        <div className="gallery">

            {props.items.map((item, i) => (
                // className='cont'
                <div className="gallery-item" key={item.id}>
                    <img
                        className='fade imgs'
                        src={require(`../images/${item.images.png}`)}
                        alt={item.name}
                    />

                </div>

            ))}


        </div>
    )
}

export default Gallery